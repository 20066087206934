@import url('https://fonts.googleapis.com/css2?family=Grape+Nuts&display=swap');

* {
  box-sizing: border-box;
  margin: 0; 
  padding: 0; 
  background-color: #238540;
}

.App {
  height: 100vh;
  font-family: 'Grape Nuts', cursive;
}

.heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.counts {
  font-weight: bold;
  font-size: 1.3em;
}

#header-btn {
  background-color: brown;
  padding: 1em;
  border-radius: 50px;
  font-weight: bold;
}

.dealerCards {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.firstCards {
  display: flex;
  position: fixed;
  bottom: 0;
  /* right: 35%; */
  width: 100%;
  justify-content: center;
  align-items: center;
}

img {
  width: 10em;
  animation: fadeIn .5s;
}

@media screen and (max-width: 900px) {
  img {
    max-width: 23vw;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1
  }
  from {
    opacity: 0
  }
}

.player-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  position: absolute;
  top: 65%;
  left: 48%;
  margin-top: -50px;
  margin-left: -50px;
}

.btns {
  background-color: brown;
  padding: 1em;
  border-radius: 50px;
  font-weight: bold;
}

button {
  cursor: pointer;
  color: black;
}

@media screen and (max-width: 550px) {
  .player-btns {
    left: 45%;
  }
}